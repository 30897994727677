import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Button,
} from "reactstrap";
import moment from "moment";
import Loader from "../../../components/Loader";
import { reportsApi } from "../../../services/reportsServices";
import ReportDateSelector from "../../../components/reports/ReportDateSelector";

const REPORT_NAME = "quarterly_inspection_report_";

const QUARTERS = [
  {
    id: "Q1",
    months: ["01 January", "02 February", "03 March"],
    color: "#B3E5A0",
  },
  {
    id: "Q2",
    months: ["04 April", "05 May", "06 June"],
    color: "#60CAF3",
  },
  {
    id: "Q3",
    months: ["07 July", "08 August", "09 September"],
    color: "#E49EDD",
  },
  {
    id: "Q4",
    months: ["10 October", "11 November", "12 December"],
    color: "#FFBF00",
  },
];

const QuarterlyInspectionReport = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const currentYear = moment().year();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const getFilename = () => `${REPORT_NAME}_${selectedYear}`;

  const formatPercentage = (value) => {
    if (value === null) return "-";
    return `${(value * 100).toFixed(2)}%`;
  };

  const formatNumber = (value) => {
    if (value === 0) return "-";
    return value;
  };

  useEffect(() => {
    const processApiResponse = (response) => {
      const tableData = [];
      const aggregatedData = {};
      QUARTERS.forEach((quarter) => {
        aggregatedData[quarter.id] = {
          months: {},
          totals: {
            assigned: 0,
            inProgress: 0,
            submitted: 0,
            percentageCompleted: 0,
            percentageInProgress: 0,
            percentageNotStarted: 0,
          },
        };

        quarter.months.forEach((month) => {
          aggregatedData[quarter.id].months[month] = {
            assigned: 0,
            inProgress: 0,
            submitted: 0,
            percentageCompleted: 0,
            percentageInProgress: 0,
            percentageNotStarted: 0,
            supervisorCount: 0,
          };
        });
      });

      Object.values(response).forEach((supervisor) => {
        const { quarters } = supervisor;

        QUARTERS.forEach((quarter) => {
          const quarterData = quarters[quarter.id];

          quarter.months.forEach((month) => {
            const monthData = quarterData[month] || {
              assigned: 0,
              inProgress: 0,
              submitted: 0,
              percentageCompleted: 0,
              percentageInProgress: 0,
              percentageNotStarted: 0,
            };

            if (monthData.assigned > 0) {
              // Only count percentages from supervisors that have assigned inspections
              aggregatedData[quarter.id].months[month].supervisorCount++;
              aggregatedData[quarter.id].months[month].percentageCompleted +=
                monthData.percentageCompleted;
              aggregatedData[quarter.id].months[month].percentageInProgress +=
                monthData.percentageInProgress;
              aggregatedData[quarter.id].months[month].percentageNotStarted +=
                monthData.percentageNotStarted;
            }

            // Add to monthly totals
            aggregatedData[quarter.id].months[month].assigned +=
              monthData.assigned;
            aggregatedData[quarter.id].months[month].inProgress +=
              monthData.inProgress;
            aggregatedData[quarter.id].months[month].submitted +=
              monthData.submitted;

            // Add to quarter totals
            aggregatedData[quarter.id].totals.assigned += monthData.assigned;
            aggregatedData[quarter.id].totals.inProgress +=
              monthData.inProgress;
            aggregatedData[quarter.id].totals.submitted += monthData.submitted;
          });
        });
      });

      QUARTERS.forEach((quarter) => {
        quarter.months.forEach((month) => {
          const monthData = aggregatedData[quarter.id].months[month];
          const supervisorCount = monthData.supervisorCount;

          const avgPercentComplete =
            supervisorCount > 0
              ? monthData.percentageCompleted / supervisorCount
              : 0;
          const avgPercentInProgress =
            supervisorCount > 0
              ? monthData.percentageInProgress / supervisorCount
              : 0;
          const avgPercentNotStarted =
            supervisorCount > 0
              ? monthData.percentageNotStarted / supervisorCount
              : 0;

          tableData.push({
            quarter: quarter.id,
            month: month.substring(3), // Remove the number prefix
            assigned: formatNumber(monthData.assigned),
            inProgress: formatNumber(monthData.inProgress),
            submitted: formatNumber(monthData.submitted),
            percentComplete: avgPercentComplete / 100,
            percentInProgress: avgPercentInProgress / 100,
            percentNotStarted: avgPercentNotStarted / 100,
            color: quarter.color,
            isTotal: false,
          });
        });

        const quarterTotals = aggregatedData[quarter.id].totals;
        if (quarterTotals.assigned > 0) {
          const quarterPercentComplete =
            quarterTotals.submitted / quarterTotals.assigned;
          const quarterPercentInProgress =
            quarterTotals.inProgress / quarterTotals.assigned;
          const quarterPercentNotStarted =
            (quarterTotals.assigned -
              quarterTotals.submitted -
              quarterTotals.inProgress) /
            quarterTotals.assigned;

          tableData.push({
            quarter: `${quarter.id} Totals`,
            month: "",
            assigned: formatNumber(quarterTotals.assigned),
            inProgress: formatNumber(quarterTotals.inProgress),
            submitted: formatNumber(quarterTotals.submitted),
            percentComplete: quarterPercentComplete,
            percentInProgress: quarterPercentInProgress,
            percentNotStarted: quarterPercentNotStarted,
            color: quarter.color,
            isTotal: true,
          });
        }
      });

      const yearTotals = {
        assigned: 0,
        inProgress: 0,
        submitted: 0,
      };

      Object.values(aggregatedData).forEach((quarterData) => {
        yearTotals.assigned += quarterData.totals.assigned;
        yearTotals.inProgress += quarterData.totals.inProgress;
        yearTotals.submitted += quarterData.totals.submitted;
      });

      if (yearTotals.assigned > 0) {
        const yearPercentComplete = yearTotals.submitted / yearTotals.assigned;
        const yearPercentInProgress =
          yearTotals.inProgress / yearTotals.assigned;
        const yearPercentNotStarted =
          (yearTotals.assigned - yearTotals.submitted - yearTotals.inProgress) /
          yearTotals.assigned;

        tableData.push({
          quarter: `${selectedYear} Totals`,
          month: "",
          assigned: formatNumber(yearTotals.assigned),
          inProgress: formatNumber(yearTotals.inProgress),
          submitted: formatNumber(yearTotals.submitted),
          percentComplete: yearPercentComplete,
          percentInProgress: yearPercentInProgress,
          percentNotStarted: yearPercentNotStarted,
          color: "#e2e3e5",
          isTotal: true,
        });
      }

      return tableData;
    };

    const fetchInspectionData = async () => {
      setLoading(true);
      try {
        const response = await reportsApi.quarterlyInspectionReport({
          year: selectedYear,
        });
        const tableData = processApiResponse(response);
        setData(tableData);
      } catch (error) {
        console.error("Error fetching inspection data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInspectionData();
  }, [selectedYear]);

  const exportToExcel = () => {
    const headers = [
      "Quarter",
      "Month",
      "Assigned",
      "In Progress",
      "Submitted",
      "% Complete",
      "% In Progress",
      "% Not Started",
    ];

    const csvContent = [
      headers.join(","),
      ...data.map((row) =>
        [
          row.quarter,
          row.month,
          row.assigned,
          row.inProgress,
          row.submitted,
          formatPercentage(row.percentComplete),
          formatPercentage(row.percentInProgress),
          formatPercentage(row.percentNotStarted),
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${getFilename()}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="mt-3">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">
                  Quarterly Inspection Report - {selectedYear}
                </h4>
                <div className="d-flex align-items-center gap-3">
                  <ReportDateSelector
                    selectedYear={selectedYear}
                    onYearChange={setSelectedYear}
                    showQuarter={false}
                  />
                  <Button
                    color="success"
                    size="md"
                    className="py-2"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>Quarter</th>
                        <th>Month</th>
                        <th>Assigned</th>
                        <th>In Progress</th>
                        <th>Submitted</th>
                        <th>% Complete</th>
                        <th>% In Progress</th>
                        <th>% Not Started</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: row.color,
                            fontWeight: row.isTotal ? "bold" : "normal",
                          }}
                        >
                          <td>{row.quarter}</td>
                          <td>{row.month}</td>
                          <td>{row.assigned === null ? "-" : row.assigned}</td>
                          <td>
                            {row.inProgress === null ? "-" : row.inProgress}
                          </td>
                          <td>
                            {row.submitted === null ? "-" : row.submitted}
                          </td>
                          <td>
                            {row.percentComplete === null
                              ? "-"
                              : formatPercentage(row.percentComplete)}
                          </td>
                          <td>
                            {row.percentInProgress === null
                              ? "-"
                              : formatPercentage(row.percentInProgress)}
                          </td>
                          <td>
                            {row.percentNotStarted === null
                              ? "-"
                              : formatPercentage(row.percentNotStarted)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default QuarterlyInspectionReport;
