import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import Loader from "../../../components/Loader";
import { reportsApi } from "../../../services/reportsServices";
import ReportDateSelector from "../../../components/reports/ReportDateSelector";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const InspectionsReportSubmitted = () => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const currentYear = moment().year();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const processApiResponse = (response) => {
    let totalAssigned = 0;
    let totalCompleted = 0;

    Object.values(response).forEach((supervisor) => {
      Object.values(supervisor.quarters).forEach((quarter) => {
        Object.values(quarter).forEach((monthData) => {
          if (typeof monthData === "object") {
            totalAssigned += monthData.assigned || 0;
            totalCompleted += monthData.submitted || 0;
          }
        });
      });
    });

    const completionPercentage =
      totalAssigned > 0
        ? Math.round((totalCompleted / totalAssigned) * 100)
        : 0;

    return {
      labels: ["Assigned", "Completed"],
      datasets: [
        {
          data: [totalAssigned, totalCompleted],
          backgroundColor: ["#2A5C81", "#F47B2C"],
          borderColor: ["#2A5C81", "#F47B2C"],
          borderWidth: 1,
        },
      ],
      completionPercentage,
    };
  };

  useEffect(() => {
    const fetchInspectionData = async () => {
      setLoading(true);
      try {
        const response = await reportsApi.quarterlyInspectionReport({
          year: selectedYear,
        });
        const data = processApiResponse(response);
        setChartData(data);
      } catch (error) {
        console.error("Error fetching inspection data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInspectionData();
  }, [selectedYear]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Inspection Reports Submitted",
        font: {
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.parsed.y}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: "#e0e0e0",
        },
        ticks: {
          stepSize: 100,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="mt-3">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">
                  Inspection Reports Submitted - {selectedYear}
                </h4>
                <ReportDateSelector
                  selectedYear={selectedYear}
                  onYearChange={setSelectedYear}
                  showQuarter={false}
                />
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                chartData && (
                  <div style={{ position: "relative", height: "400px" }}>
                    <Bar data={chartData} options={chartOptions} />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      {chartData.completionPercentage}%
                    </div>
                  </div>
                )
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InspectionsReportSubmitted;
