import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const inspectionsApi = {
  getInspections: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id ? `/api/inspections/${data.id}` : `/api/inspections?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateInspection: (data) => {
    return axios(`/api/inspections/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getInspectionsTypes: () =>
    axios(`/api/inspection-types`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  createInspectionTypeWorkOrder: (data) =>
    axios(`/api/inspection-type-work-orders`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  createInspectionSaleItem: (data) =>
    axios(`/api/inspection-sale-items`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  deleteInspectionSaleItem: (data) =>
    axios(`/api/inspection-sale-items/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  updateInspectionSaleItem: (data) =>
    axios(`/api/inspection-sale-items/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  getInspectionSaleItems: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/inspection-sale-items?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteInspectionAssignation: (data) =>
    axios(`/api/inspection-type-work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  updateInspectionTypeWorkOrder: (data) =>
    axios(`/api/inspection-type-work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  updateInspectionAnswer: (data) =>
    axios(`/api/inspection-answers/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  deleteInspection: (data) =>
    axios(`/api/inspections/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  getInspectionTypeWorkOrderReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/inspection-type-work-orders/report?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
