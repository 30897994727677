import { faFileDownload, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

import { utilsHelper } from "../helpers/utilsHelper";
import { employeeSafetyCertificationsApi } from "../services/employeeSafetyCertificationsService";
import InformationModal from "./InformationModal";
import Loader from "./Loader";
import DocumentPreviewModal from "./admin/DocumentPreviewModal";
import { useDocumentPreviewModal } from "../helpers/hooks/useDocumentPreviewModal";
import EmailRecipientsModal from "./EmailRecipientsModal";

const SafetyCertificationsModal = ({ onClose, employee }) => {
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [certifications, setCertifications] = useState([]);

  const {
    informationModal,
    setInformationModal,
    openDocumentPreview,
    documentPreviewModal,
    emailRecipientsModal,
    openSendEmailModal,
  } = useDocumentPreviewModal();

  useEffect(() => {
    if (employee.id) {
      setLoading(true);

      const init = async () => {
        const response = await employeeSafetyCertificationsApi.getAll({
          employeeId: employee.id,
          pageSize: 100,
        });

        const values = response;

        if (values?.data) {
          setCertifications(values.data);
        }

        setLoading(false);
      };

      init();
    }
  }, [employee]);

  const isExpired = (certification) => {
    const today = new Date();
    const expirationDate = new Date(certification.certExpiration);

    return today > expirationDate;
  };

  const isToExpire = (certification) => {
    const today = new Date();
    const expirationDate = new Date(certification.certExpiration);
    const diffTime = expirationDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 60;
  };

  const downloadFile = async (cert) => {
    try {
      setLoadingDownload(true);
      await employeeSafetyCertificationsApi.getSignedFile(cert.id);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Download Certificate",
        body: isExpired(cert)
          ? "Expired Certificate is not avaible to download"
          : "Employee Certificate not found or is not available to download",
        onClose: () => setInformationModal({ isOpen: false }),
      });
      setLoadingDownload(false);
    } finally {
      setLoadingDownload(false);
    }
  };

  const downloadSafetyCertifications = async () => {
    try {
      setLoadingDownload(true);
      await employeeSafetyCertificationsApi.getAllEmployeeSafetyCertifications({
        employeeId: employee.id,
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Download Certificates",
        body: "Employee Certificates not found or are not available to download",
        onClose: () => setInformationModal({ isOpen: false }),
      });
      setLoadingDownload(false);
    } finally {
      setLoadingDownload(false);
    }
  };

  const hasCertifications = certifications.length > 0;

  return emailRecipientsModal.open ? (
    <EmailRecipientsModal {...emailRecipientsModal} />
  ) : informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={informationModal.onClose}
    />
  ) : documentPreviewModal.isOpen ? (
    <DocumentPreviewModal {...documentPreviewModal} pageWidth={800} />
  ) : (
    <Modal isOpen={true} toggle={onClose} size="md">
      <ModalHeader>Safety Certifications</ModalHeader>

      <ModalBody>
        {loading ? (
          <Loader size={"sm"} />
        ) : hasCertifications ? (
          <>
            <div className="d-flex m-2 mb-4 justify-content-end">
              <Button
                onClick={() => downloadSafetyCertifications()}
                className="rounded mr-2"
                color="success"
                size="sm"
                disabled={loadingDownload}
              >
                Download All{" "}
                <FontAwesomeIcon
                  className="ml-1"
                  size="md"
                  icon={faFileDownload}
                />
              </Button>
              <Button
                onClick={() => openSendEmailModal(employee)}
                className="rounded"
                color="success"
                size="sm"
              >
                Email All{" "}
                <FontAwesomeIcon className="ml-1" size="md" icon={faEnvelope} />
              </Button>
            </div>
            <Table className="border col-12 px-0 mb-0 small">
              <tbody>
                {certifications.map((certification) => (
                  <tr>
                    <td className="font-weight-bold">
                      {certification.certDescription}
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <Badge
                          color={
                            isExpired(certification)
                              ? "danger"
                              : isToExpire(certification)
                              ? "six"
                              : "eight"
                          }
                        >
                          {utilsHelper.formatUTCDate(
                            certification.certExpiration,
                            "MM/DD/YYYY"
                          )}
                        </Badge>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="rounded mr-2"
                          color="seven"
                          size="sm"
                          disabled={certification.certLink ? false : true}
                          onClick={() =>
                            openDocumentPreview(
                              certification,
                              certification.employee
                            )
                          }
                        >
                          View
                        </Button>

                        <Button
                          className="rounded"
                          color="link"
                          size="sm"
                          onClick={() => downloadFile(certification)}
                          disabled={
                            certification.certLink && !loadingDownload
                              ? false
                              : true
                          }
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={faFileDownload}
                            className="text-seven"
                          />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <div className="text-center text-muted mt-4 mb-4 small">
            No data has been recorded
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SafetyCertificationsModal;
