import React from "react";
import Select from "react-select";
import moment from "moment";

const MIN_YEAR = 1800;
const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

const ReportDateSelector = ({
  selectedYear,
  selectedQuarter,
  onYearChange,
  onQuarterChange,
  showQuarter = true,
}) => {
  const currentYear = moment().year();

  // Generate years array from MIN_YEAR to current year
  const years = Array.from(
    { length: currentYear - MIN_YEAR + 1 },
    (_, i) => currentYear - i
  ).map((year) => ({
    value: year,
    label: year.toString(),
  }));

  const quarters = QUARTERS.map((quarter) => ({
    value: quarter,
    label: quarter,
  }));

  const customStyles = {
    control: (base) => ({
      ...base,
      minWidth: 100,
      maxWidth: 150,
    }),
  };

  return (
    <div className="d-flex gap-3 align-items-end mr-3">
      <div className="d-flex align-items-center">
        <label className="form-label mr-2 mb-0">Year</label>
        <Select
          value={years.find((y) => y.value === selectedYear)}
          onChange={(option) => onYearChange(option.value)}
          options={years}
          styles={customStyles}
          isSearchable
          menuPlacement="auto"
          classNamePrefix="react-select"
        />
      </div>
      {showQuarter && (
        <div className="d-flex align-items-center ml-3">
          <label className="form-label mr-2 mb-0">Quarter</label>
          <Select
            value={quarters.find((q) => q.value === selectedQuarter)}
            onChange={(option) => onQuarterChange(option.value)}
            options={quarters}
            styles={customStyles}
            isSearchable={false}
            menuPlacement="auto"
            classNamePrefix="react-select"
          />
        </div>
      )}
    </div>
  );
};

export default ReportDateSelector;
