import {
  faChevronDown,
  faDownload,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Collapse,
  CustomInput,
} from "reactstrap";

import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import { Document, Page } from "react-pdf";
import Loader from "../../components/Loader";
import { reportsApi } from "../../services/reportsServices";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { inspectionsApi } from "../../services/inspectionServices";
import SendInspectionReportEmailModal from "../../components/admin/SendInspectionReportEmailModal";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";

const STATUS_IN_PROGRESS = "IN_PROGRESS";
const STATUS_COMPLETED = "COMPLETED";
const STATUS_REVIEWED = "REVIEWED";

const INSPECTION_GRADES = ["A", "B", "C", "D", "F"];

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WorkOrderInspectionReports = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [selectedInspection, setSelectedInspection] = useState(null);
  const [loading, setLoading] = useState();
  const [gradeLoading, setGradeLoading] = useState();
  const [reportURL, setReportURL] = useState();
  const [rawReport, setRawReport] = useState();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [isOpen, setIsOpen] = useState({});

  const [sendReportEmail, setSendReportEmail] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onDownload = () => {
    const link = document.createElement("a");
    link.href = reportURL;
    link.setAttribute(
      "download",
      `${selectedInspection.equipment} ${selectedInspection.inspectionType.name}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (selectedInspection) {
      setReportURL();
      setLoading(true);
      setPageNumber(1);
      reportsApi
        .inspectionSummaryReport({ inspectionId: selectedInspection.id })
        .then((res) => {
          const data = new Blob([res], { type: "application/pdf" });
          const URL = window.URL.createObjectURL(data);
          setLoading(false);
          setReportURL(URL);
          setRawReport(data);
        });
      inspectionsApi
        .getInspections({ id: selectedInspection.id })
        .then((res) => {
          if (selectedInspection.grade !== res.grade) {
            setSelectedInspection({
              ...selectedInspection,
              grade: res.grade,
            });
          }
        })
        .catch(() => {
          setReportURL(null);
          setLoading(false);
        });
    }
  }, [selectedInspection]);

  useEffect(() => {
    if (workOrderDetails.workOrder?.inspectionTypeWorkOrders) {
      setIsOpen(
        workOrderDetails.workOrder.inspectionTypeWorkOrders.reduce((p, c) => {
          p[c.id] = true;
          return p;
        }, {})
      );
    }
  }, [workOrderDetails.workOrder.inspectionTypeWorkOrders]);

  const onSubmittedCustomer = (evt, inspection) => {
    evt.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      confirmColor: "warning",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await inspectionsApi.updateInspection({
          ...inspection,
          reviewedAt: new Date(),
          status: "REVIEWED",
        });
        setInformationModal({
          isOpen: true,
          title: `Mark Inspection as Reviewed`,
          body: `Inspection marked as reviewed successfully`,
          onClose: () => {
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Mark Inspection as Reviewed",
      body: `Do you confirm you want to mark this inspection as reviewed?`,
    });
  };

  const getInspectionStatus = (inspection) => {
    const inspectionStatus = { status: "Assigned", color: "secondary" };
    if (inspection.status === STATUS_IN_PROGRESS) {
      inspectionStatus.status = "In Progress";
      inspectionStatus.color = "warning";
    } else if (inspection.status === STATUS_COMPLETED) {
      inspectionStatus.status = `Completed at ${moment(
        inspection.completedAt
      ).format("MM-DD-YYYY, hh:mm a")}`;
      inspectionStatus.color = "success";
    } else if (inspection.status === STATUS_REVIEWED) {
      inspectionStatus.status = "Reviewed";
      inspectionStatus.color = "info";
    }
    return inspectionStatus;
  };

  const onGradeChange = (inspection, grade) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "warning",
      onSubmit: async () => {
        setGradeLoading(true);
        setConfirmationModal(initConfirmationModal);
        try {
          await inspectionsApi.updateInspection({
            ...inspection,
            grade,
          });
          setGradeLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Change Inspection Grading`,
            body: `Grading changed successfully`,
          });
          selectedInspection.grade = grade;
        } catch (err) {
          setGradeLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Change Inspection Grading`,
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Change Inspection Grading",
      body: `Do you confirm you want to change this inspection grading?`,
    });

  return (
    <Row className="d-flex">
      <Col className="flex-shrink-0">
        <div className="d-flex d-flex-row justify-content-between border-bottom">
          <h4>Inspection Reports</h4>
          <div>
            {reportURL ? (
              <div className="d-flex align-items-center mb-2">
                {selectedInspection ? (
                  <>
                    {!gradeLoading ? (
                      <div className="ml-2">
                        <CustomInput
                          id={`grade-${selectedInspection.id}`}
                          type="select"
                          name={`grade-${selectedInspection.id}`}
                          value={selectedInspection.grade || ""}
                          onChange={(event) =>
                            onGradeChange(
                              selectedInspection,
                              event.currentTarget.value
                            )
                          }
                        >
                          <option value={""}>Select grade..</option>
                          {INSPECTION_GRADES.map((grade) => {
                            return (
                              <option key={grade} value={grade}>
                                Grade {grade}
                              </option>
                            );
                          })}
                        </CustomInput>
                      </div>
                    ) : (
                      <div style={{ width: "20px" }}>
                        <Loader size="sm" />
                      </div>
                    )}
                  </>
                ) : null}
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    color="tertiary"
                    size="sm"
                    className="rounded ml-2"
                    onClick={onDownload}
                  >
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    <span>PDF</span>
                  </Button>
                ) : null}

                <Button
                  color="secondary"
                  size="sm"
                  className="rounded ml-2"
                  onClick={() => setSendReportEmail(true)}
                >
                  <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                  <span>Email</span>
                </Button>
              </div>
            ) : (
              <Badge className="d-flex align-items-center" pill>
                Click on an entry to see the report
              </Badge>
            )}
          </div>
        </div>
        {!workOrderDetails.workOrder?.inspectionTypeWorkOrders?.length ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          <Row className="d-flex">
            <Col>
              {!workOrderDetails.workOrder.inspectionTypeWorkOrders.length ? (
                <div className="text-center text-muted mt-4 small">
                  No data has been recorded
                </div>
              ) : (
                <ListGroup className="mt-1">
                  {workOrderDetails.workOrder.inspectionTypeWorkOrders.map(
                    (inspectionTypeWorkOrder) => {
                      return (
                        <div key={inspectionTypeWorkOrder.id} className="my-1">
                          <Button
                            onClick={() =>
                              setIsOpen({
                                ...isOpen,
                                [inspectionTypeWorkOrder.id]:
                                  !isOpen[inspectionTypeWorkOrder.id],
                              })
                            }
                            style={{ width: "100%" }}
                          >
                            <div className="d-flex justify-content-between align-items-center m-1">
                              <div>
                                <span>{inspectionTypeWorkOrder.equipment}</span>
                                {" - "}
                                <Badge className="ml-1 bg-white text-secondary">
                                  {inspectionTypeWorkOrder.inspectionType.name}
                                </Badge>
                              </div>
                              <FontAwesomeIcon
                                icon={
                                  isOpen[inspectionTypeWorkOrder.id]
                                    ? faChevronDown
                                    : faChevronRight
                                }
                                fixedWidth
                                className="my-1"
                              />
                            </div>
                          </Button>
                          <Collapse
                            isOpen={isOpen[inspectionTypeWorkOrder.id]}
                            className="px-2"
                          >
                            <ListGroup className="my-2">
                              {inspectionTypeWorkOrder.inspections.length ? (
                                inspectionTypeWorkOrder.inspections.map(
                                  (inspection, index) => {
                                    const inspectionStatus =
                                      getInspectionStatus(inspection);
                                    return (
                                      <ListGroupItem
                                        key={index}
                                        className="d-flex justify-content-between align-items-center py-2 bg-lighter cursor-pointer"
                                        tag="div"
                                        onClick={() =>
                                          setSelectedInspection({
                                            ...inspection,
                                            equipment:
                                              inspectionTypeWorkOrder.equipment,
                                            inspectionType:
                                              inspectionTypeWorkOrder.inspectionType,
                                          })
                                        }
                                      >
                                        <small className="text-muted">
                                          {moment(inspection.date).format(
                                            "MM/DD/YYYY"
                                          )}
                                        </small>
                                        <div className="d-flex align-items-center">
                                          <Badge
                                            className="d-flex align-items-center"
                                            pill
                                            color={inspectionStatus.color}
                                          >
                                            {inspectionStatus.status}
                                          </Badge>

                                          {inspection.status ===
                                            STATUS_COMPLETED &&
                                          !utilsHelper.isReadOnly(
                                            authContext
                                          ) ? (
                                            <Button
                                              color="warning"
                                              size="sm"
                                              className="ml-2 rounded"
                                              onClick={(evt) =>
                                                onSubmittedCustomer(
                                                  evt,
                                                  inspection
                                                )
                                              }
                                            >
                                              Submitted to Customer
                                            </Button>
                                          ) : null}
                                        </div>
                                      </ListGroupItem>
                                    );
                                  }
                                )
                              ) : (
                                <ListGroupItem
                                  className="d-flex justify-content-between align-items-center py-2 small text-muted bg-lighter"
                                  tag="div"
                                >
                                  No inspections to show
                                </ListGroupItem>
                              )}
                            </ListGroup>
                          </Collapse>
                        </div>
                      );
                    }
                  )}
                </ListGroup>
              )}
            </Col>
            <Col className="flex-grow-1 d-flex flex-column col my-3">
              {loading ? (
                <div className="my-2">
                  <Loader size="sm" />
                </div>
              ) : !reportURL ? (
                <div className="text-center text-muted mt-4">
                  No PDF has been loaded
                </div>
              ) : (
                <>
                  <Document
                    className="d-flex flex-column justify-content-between align-items-center text-body bg-white"
                    file={reportURL}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page
                      className="w-100 border rounded"
                      pageNumber={pageNumber}
                    />
                  </Document>
                  <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
                    <Button
                      className="rounded"
                      onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <span>
                      Page {pageNumber} of {numPages}
                    </span>
                    <Button
                      className="rounded"
                      onClick={() =>
                        setPageNumber(Math.min(pageNumber + 1, numPages))
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        )}
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : sendReportEmail ? (
        <SendInspectionReportEmailModal
          onClose={() => setSendReportEmail(false)}
          pdf={rawReport}
          fileName={`${selectedInspection.inspectionType.name}_${selectedInspection.date}.pdf`}
        />
      ) : null}
    </Row>
  );
};

export default WorkOrderInspectionReports;
