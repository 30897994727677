import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Button,
} from "reactstrap";
import moment from "moment";
import Loader from "../../../components/Loader";
import { reportsApi } from "../../../services/reportsServices";
import ReportDateSelector from "../../../components/reports/ReportDateSelector";

const REPORT_NAME = "inspections_report_completed_";

const InspectionsReportCompleted = () => {
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState(null);
  const [data, setData] = useState([]);
  const currentYear = moment().year();
  const defaultQuarter = `Q${Math.ceil((moment().month() + 1) / 3)}`;

  const [selectedQuarter, setSelectedQuarter] = useState(defaultQuarter);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const getFilename = () => `${REPORT_NAME}_${selectedYear}_${selectedQuarter}`;

  const formatPercentage = (value) => {
    if (value === null || value === undefined) return "-";
    return `${value.toFixed(0)}%`;
  };

  const formatMissingInspections = (workOrders) => {
    if (!workOrders || workOrders.length === 0) return "";

    const totalPercentage = workOrders.reduce(
      (sum, wo) => sum + wo.percentageMissing,
      0
    );
    const averagePercentage =
      workOrders.length > 0
        ? Math.round(totalPercentage / workOrders.length)
        : 0;

    const workOrdersText = workOrders
      .map((wo) => {
        const missingCount = wo.missingInspections;
        const woNumber = wo.workOrderNumber;
        return `(${missingCount}) ${woNumber}`;
      })
      .join(", ");

    return `${workOrdersText} /${averagePercentage}%`;
  };

  const processApiResponse = useCallback(
    (response, quarter) => {
      const currentDate = moment();
      const quarterNumber = parseInt(quarter.substring(1));
      const quarterStartMonth = (quarterNumber - 1) * 3;
      const quarterStart = moment()
        .year(selectedYear)
        .month(quarterStartMonth)
        .startOf("month");
      const quarterEnd = moment(quarterStart).add(3, "months");
      const isFutureQuarter = currentDate.isBefore(quarterStart);

      return Object.values(response)
        .map((supervisor) => ({
          name: `${supervisor.firstName} ${supervisor.lastName}`,
          totalAssigned: isFutureQuarter
            ? 0
            : supervisor.totalInspectionsAssigned,
          totalCompleted: isFutureQuarter
            ? 0
            : supervisor.totalInspectionsCompleted,
          completionPercentage: isFutureQuarter
            ? 0
            : supervisor.completionPercentage,
          missingInspections: isFutureQuarter
            ? []
            : supervisor.workOrdersWithMissingInspections.filter((wo) => {
                const createdAt = moment(
                  wo.inspectionTypeWorkOrders[0]?.createdAt
                );
                return createdAt.isBetween(
                  quarterStart,
                  quarterEnd,
                  null,
                  "[]"
                );
              }),
        }))
        .sort((a, b) => b.completionPercentage - a.completionPercentage);
    },
    [selectedYear]
  );

  useEffect(() => {
    const fetchInspectionData = async () => {
      setLoading(true);
      try {
        const response = await reportsApi.quarterlyInspectionReport({
          year: selectedYear,
        });
        setRawData(response);
        const tableData = processApiResponse(response, selectedQuarter);
        setData(tableData);
      } catch (error) {
        console.error("Error fetching inspection data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInspectionData();
  }, [processApiResponse, selectedQuarter, selectedYear]);

  useEffect(() => {
    if (rawData) {
      const tableData = processApiResponse(rawData, selectedQuarter);
      setData(tableData);
    }
  }, [selectedQuarter, rawData, processApiResponse]);

  const exportToExcel = () => {
    const headers = [
      "Employee",
      "Total Assigned",
      "Total Completed",
      "Completion Percentage",
      `Missing Inspections /PWO# ${selectedQuarter}`,
    ];

    const csvContent = [
      headers.join(","),
      ...data.map((row) =>
        [
          row.name,
          row.totalAssigned,
          row.totalCompleted,
          formatPercentage(row.completionPercentage),
          formatMissingInspections(row.missingInspections),
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${getFilename()}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="mt-3">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">
                  Total Completed Inspections Report - {selectedYear}
                </h4>
                <div className="d-flex align-items-center gap-3">
                  <ReportDateSelector
                    selectedYear={selectedYear}
                    selectedQuarter={selectedQuarter}
                    onYearChange={setSelectedYear}
                    onQuarterChange={setSelectedQuarter}
                  />
                  <Button
                    color="success"
                    size="md"
                    className="py-2"
                    onClick={exportToExcel}
                  >
                    Export Excel
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th style={{ width: "25%", whiteSpace: "normal" }}>
                          Employee
                        </th>
                        <th style={{ width: "8%", whiteSpace: "nowrap" }}>
                          Total Assigned
                        </th>
                        <th style={{ width: "8%", whiteSpace: "nowrap" }}>
                          Total Completed
                        </th>
                        <th style={{ width: "12%", whiteSpace: "nowrap" }}>
                          Completion Percentage
                        </th>
                        <th style={{ width: "47%", whiteSpace: "normal" }}>
                          Missing Inspections /PWO# {selectedQuarter}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row, index) => (
                        <tr key={index}>
                          <td style={{ whiteSpace: "normal" }}>{row.name}</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {row.totalAssigned}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {row.totalCompleted}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {formatPercentage(row.completionPercentage)}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {formatMissingInspections(row.missingInspections)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InspectionsReportCompleted;
