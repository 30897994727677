import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsHelper } from "../../../helpers/reportsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";
import { inspectionsApi } from "../../../services/inspectionServices";

const NAME_COLUMN = "Work Order #";
const STATUS_ASSIGNED = "ASSIGNED";
const STATUS_IN_PROGRESS = "IN_PROGRESS";
const STATUS_COMPLETED = "COMPLETED";
const STATUS_REVIEWED = "REVIEWED";

const STATUS_MAP = {
  [STATUS_IN_PROGRESS]: "In Progress",
  [STATUS_COMPLETED]: "Completed",
  [STATUS_REVIEWED]: "Reviewed",
  [STATUS_ASSIGNED]: "Assigned",
};

const dataSourceValue = [
  {
    "Customer Name": {
      type: "string",
    },
    Employee: {
      type: "string",
    },
    "Work Order #": {
      type: "string",
    },
    "Inspection Name": {
      type: "string",
    },
    Grade: {
      type: "string",
    },
    Equipment: {
      type: "string",
    },
    Status: {
      type: "string",
    },
    "Assigned Date": {
      type: "string",
    },
    "In Progress Date": {
      type: "string",
    },
    "Completed Date": {
      type: "string",
    },
    "Reviewed Date": {
      type: "string",
    },
  },
];

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: dataSourceValue,
  },
  slice: {
    rows: [
      {
        uniqueName: "Customer Name",
      },
      {
        uniqueName: "Employee",
      },
      {
        uniqueName: "Work Order #",
      },
      {
        uniqueName: "Inspection Name",
      },
      {
        uniqueName: "Grade",
      },
      {
        uniqueName: "Equipment",
      },
      {
        uniqueName: "Status",
      },
      { uniqueName: "Assigned Date" },
      { uniqueName: "In Progress Date" },
      {
        uniqueName: "Completed Date",
      },
      { uniqueName: "Reviewed Date" },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Inspections Report",
    },
  },
};

const REPORT_NAME = "inspections_report_";
const TYPE_WEEKLY = "TYPE_WEEKLY";

const InspectionsReport = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }

    inspectionsApi
      .getInspectionTypeWorkOrderReport(condition)
      .then((result) => {
        setData(result);
        setLoading(false);
        if (pivot) {
          const parsedResult = result.flatMap((inspectionTypeWorkOrder) => {
            // if no inspections show just assigned inspection type work order
            if (!inspectionTypeWorkOrder.inspections?.length) {
              return [
                {
                  "Customer Name":
                    inspectionTypeWorkOrder.workOrder?.customerName || "-",
                  Employee: "-",
                  "Work Order #": inspectionTypeWorkOrder.workOrder
                    ? utilsHelper.getWorkOrderNumberWithGrouped(
                        inspectionTypeWorkOrder.workOrder
                      )
                    : "-",
                  "Inspection Name":
                    inspectionTypeWorkOrder.inspectionType?.name || "-",
                  Grade: "-",
                  Equipment: inspectionTypeWorkOrder.equipment || "-",
                  Status: STATUS_MAP[STATUS_ASSIGNED],
                  "Assigned Date": inspectionTypeWorkOrder.createdAt
                    ? moment(inspectionTypeWorkOrder.createdAt).format(
                        "MM/DD/YYYY"
                      )
                    : "-",
                  "In Progress Date": "-",
                  "Completed Date": "-",
                  "Reviewed Date": "-",
                },
              ];
            }

            return inspectionTypeWorkOrder.inspections.map((inspection) => ({
              "Customer Name":
                inspectionTypeWorkOrder.workOrder?.customerName || "-",
              Employee: inspection?.user?.employee
                ? utilsHelper.getEmployeeReverseLabelWithPreferredName(
                    inspection.user.employee
                  )
                : "-",
              "Work Order #": inspectionTypeWorkOrder.workOrder
                ? utilsHelper.getWorkOrderNumberWithGrouped(
                    inspectionTypeWorkOrder.workOrder
                  )
                : "-",
              "Inspection Name":
                inspectionTypeWorkOrder.inspectionType?.name || "-",
              Grade: inspection?.grade || "-",
              Equipment: inspectionTypeWorkOrder.equipment || "-",
              Status: STATUS_MAP[inspection.status] || inspection.status,
              "Assigned Date": inspectionTypeWorkOrder.createdAt
                ? moment(inspectionTypeWorkOrder.createdAt).format("MM/DD/YYYY")
                : "-",
              "In Progress Date": inspection?.createdAt
                ? moment(inspection.createdAt).format("MM/DD/YYYY")
                : "-",
              "Completed Date": inspection?.completedAt
                ? moment(inspection.completedAt).format("MM/DD/YYYY")
                : "-",
              "Reviewed Date": inspection?.reviewedAt
                ? moment(inspection.reviewedAt).format("MM/DD/YYYY")
                : "-",
            }));
          });

          pivot.current.flexmonster.updateData({
            data: [dataSourceValue?.[0], ...parsedResult],
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [
    monday,
    setData,
    periodType,
    pivot,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        reportsHelper.navigateToWorkOrder(
          cell,
          data,
          NAME_COLUMN,
          null,
          navigate
        );
      });
    }
  }, [pivot, data, navigate]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  //key={(monday + periodType.value).toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InspectionsReport;
