import {
  faDatabase,
  faEnvelope,
  faPen,
  faSms,
  faTrash,
  faPlus,
  faUserClock,
  faCar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Badge,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";

import ConfirmationModal from "../../components/ConfirmationModal";
import ContactModal from "../../components/ContactModal";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import { crewsApi } from "../../services/crewServices";
import { communicationLogApi } from "../../services/communicationLogServices";

import { utilsHelper } from "../../helpers/utilsHelper";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import AddWoCrewModal from "../../components/admin/AddWoCrewModal";
import WorkOrderCrewIndicators from "../../components/admin/workOrderDetails/WorkOrderCrewIndicators";
import EmployeesWorkDays from "../../components/admin/workOrderDetails/EmployeesWorkDays";
import AddDriverModal from "../../components/admin/workOrderDetails/AddDriverModal";
import { useAuth } from "../../providers/authProvider";

const NONJOB = "NONJOB";
const OUTAGE_NONE = "NONE";
const STATUS_INVOICED = 6;
const TYPE_VAN_DRIVER = "TYPE_VAN_DRIVER";
const DEPARTMENT_PRECSN = "PRECSN";

const DEFAULT_EMAIL_CONTENT = `
  <p>Please find your supervisor’s information and site address below.</p>
  <p>Amy Eddins will be sending your safety information shortly.</p>
  <p>If you have a question about safety, please direct it to her.</p>
  <p>If you have any questions about the job, please direct them to me or your supervisor.</p>
  <p>Please give yourself plenty of time to get through the gate.</p>
`;

const WorkOrderCrewDetails = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const navigate = useNavigate();

  const [loading, setLoading] = useState({});
  const [addWoCrewModal, setAddWoCrewModal] = useState();
  const [vanDriverModal, setVanDriverModal] = useState({});

  const [contactModal, setContactModal] = useState({
    isOpen: false,
    isEmail: false,
    employees: null,
    supervisor: null,
    defaultRecipients: [],
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const getShiftCrewSize = (shift) =>
    workOrderDetails.workOrder.workOrderCrews
      .filter((woc) => woc.shift === shift)
      .reduce((p, c) => p + c.count, 0);

  const getEmployeesByRole = (crew) =>
    crew.employeeCrews
      .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec, false))
      .sort((ec) => (ec.isCrewLead ? -1 : 1))
      .reduce((p, c) => {
        p[c.role.name] = [
          ...(p[c.role.name] || []),
          {
            ...c.employee,
            createdAt: c.createdAt,
            roleId: c.role.id,
            isOverage: c.isOverage,
            isCrewLead: c.isCrewLead,
            employeeCrewAuthor: c.employeeCrewAuthor,
          },
        ];
        return p;
      }, {});

  const getCrewEmails = (crew) =>
    crew.employeeCrews
      .filter((ec) => ec.employee.email && !ec.disabledAt)
      .map((ec) => ({
        name: `${ec.employee.firstName} ${ec.employee.lastName}`,
        content: ec.employee.email,
      }));

  const getCrewPhones = (crew) =>
    crew.employeeCrews
      .filter((ec) => ec.employee.phone && !ec.disabledAt)
      .map((ec) => ({
        name: `${ec.employee.firstName} ${ec.employee.lastName}`,
        content: ec.employee.phone,
      }));

  const getCrewEmailDefaultTemplate = (crew) => {
    const crewSupervisor = crew.employeeCrews?.find((ec) => ec.isCrewLead);

    return `${DEFAULT_EMAIL_CONTENT}
    <p><strong>Supervisor:</strong></p>
    <ul>
      <li class="text-underline">${crewSupervisor?.employee.firstName} ${
      crewSupervisor?.employee.lastName
    } ${
      crewSupervisor?.employee.phone
        ? `- ${crewSupervisor?.employee.phone}`
        : ""
    }</li>
    </ul>
    `;
  };

  const onCrewHistory = (crew) => {
    return setInformationModal({
      isOpen: true,
      title: "Crew History",
      rawBody: true,
      size: "xl",
      body: (
        <Table className="col-12 px-0 mb-0 border" stripped="true">
          <thead>
            <tr className="bg-lighter small">
              <th>Name</th>
              <th>Role</th>
              <th>Crew Lead</th>
              <th>Added to Crew</th>
              <th>Removed from Crew</th>
            </tr>
          </thead>
          <tbody className="small">
            {crew.employeeCrews
              .sort((x, y) =>
                y.employee.firstName < x.employee.firstName ? 1 : -1
              )
              .map((employeeCrew) => {
                return (
                  <tr key={employeeCrew.id}>
                    <td>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</td>
                    <td>{employeeCrew.role.name}</td>
                    <td>{employeeCrew.isCrewLead ? "Yes" : "No"}</td>
                    <td>
                      {utilsHelper.formatDate(
                        employeeCrew.createdAt,
                        "MM/DD/YYYY"
                      )}
                    </td>
                    <td>
                      {employeeCrew.disabledAt
                        ? `${utilsHelper.formatDate(
                            employeeCrew.disabledAt,
                            "MM/DD/YYYY"
                          )} by ${
                            employeeCrew.employeeCrewEditor?.firstName || "-"
                          } ${employeeCrew.employeeCrewEditor?.lastName || ""}`
                        : "-"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ),
    });
  };

  const onDeleteCrew = (crewId) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setLoading({ [crewId]: true });
        setConfirmationModal(initConfirmationModal);
        crewsApi
          .deleteCrew({
            id: crewId,
          })
          .then(() => {
            setLoading({ [crewId]: false });
            return setInformationModal({
              isOpen: true,
              title: "Remove Crew",
              body: "Crew Removed Successfully.",
              onClose: () =>
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                }),
            });
          })
          .catch(() => {
            setLoading({ [crewId]: false });
            return setInformationModal({
              isOpen: true,
              title: "Remove Crew",
              body: "There was an error with your request.",
            });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Crew",
      body: `<span class="text-center">Do you confirm you want to remove this crew from the Work Order including all its associated data?</span>`,
    });
  };

  const afterSubmitContactModal = (crewId, type, data) => {
    const workOrderId = workOrderDetails.workOrder.id;
    const payload = {
      ...data,
      workOrderId,
      crewId,
      type,
      recipients: data.recipients.split(","),
    };
    communicationLogApi.createCommunicationLog(payload);
  };

  const onCrewComposition = () =>
    setInformationModal({
      isOpen: true,
      rawBody: true,
      title: `Work Order Crews Composition`,
      body: (
        <div className="d-flex">
          <Table className="col-12 px-0 mb-0 small border" stripped="true">
            <thead>
              <tr className="bg-lighter">
                <th>Shift</th>
                <th>Role</th>
                <th>Outage</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {workOrderDetails.workOrder.workOrderCrews
                .sort((a, b) =>
                  a.shift < b.shift ? -1 : b.shift < a.shift ? 1 : 0
                )
                .map(({ id, shift, outage, role, count }) => (
                  <tr key={id}>
                    <td>{shift}</td>
                    <td>{role?.name || "-"}</td>
                    <td>{outage || "NONE"}</td>
                    <td>{count}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      ),
    });

  const onAddCrew = () => {
    if (
      !workOrderDetails.workOrder.startDate ||
      !workOrderDetails.workOrder.startDate
    ) {
      return setInformationModal({
        isOpen: true,
        title: "Add Crew to Work Order",
        body: "You can only add crews to a workOrder once an admin has set its start and end dates..",
      });
    }

    const { workOrder } = workOrderDetails;
    const isDepartmentPRECSN = workOrder.department === DEPARTMENT_PRECSN;
    const isNonJob = workOrder.workOrderType === NONJOB;

    if (isNonJob || isDepartmentPRECSN) {
      navigate(
        `/work-orders/details/${workOrderDetails.workOrder.id}/crew/members`
      );
    } else {
      setAddWoCrewModal(true);
    }
  };

  const onEditCrew = (shift, outage, crew) => {
    if (workOrderDetails.workOrder.workOrderType === NONJOB) {
      navigate(
        `/work-orders/details/${workOrderDetails.workOrder.id}/crew/members/${crew.id}`
      );
    } else {
      navigate(
        `/work-orders/details/${workOrderDetails.workOrder.id}/crew/${shift}/${
          outage || OUTAGE_NONE
        }/members/${crew.id}`
      );
    }
  };

  return (
    <Row>
      <Col sm="12">
        <div className="d-flex d-flex-row justify-content-between border-bottom">
          <h4>Crews</h4>
          <div>
            <Button
              color="secondary"
              size="sm"
              className="ml-2 rounded"
              onClick={onCrewComposition}
            >
              <FontAwesomeIcon icon={faDatabase} className="mr-2" />
              <span>Crews Composition</span>
            </Button>
            {workOrderDetails.workOrder.woParentId ||
            workOrderDetails.workOrder.statusId === STATUS_INVOICED ||
            utilsHelper.isReadOnly(authContext) ? null : (
              <Button
                size="sm"
                color="primary rounded ml-2"
                onClick={onAddCrew}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>Add Crew</span>
              </Button>
            )}
          </div>
        </div>
        {!workOrderDetails.workOrder?.crewWorkOrders?.length ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          workOrderDetails.workOrder.crewWorkOrders
            .sort((a, b) =>
              a.shift < b.shift ? -1 : b.shift < a.shift ? 1 : 0
            )
            .map(({ id, shift, outage, crew, employeeWorkDays }) => {
              const employeesByRoles = getEmployeesByRole(crew);
              const crewLead = crew.employeeCrews
                .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                .find((ec) => ec.isCrewLead);
              return (
                <div className="col-12 mt-3 border px-0 rounded" key={id}>
                  <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                    <div className="d-flex align-items-center col-3 px-0">
                      <small className="text-white px-1 border border-white rounded font-weight-bold">
                        {utilsHelper.outageLabels(outage)}
                      </small>
                    </div>
                    <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                      {utilsHelper.capitalize(shift)} Crew
                    </div>
                    <div className="col-3 d-flex justify-content-end align-items-center px-0">
                      {loading[crew.id] ? (
                        <Loader size="sm" align="end" color="white" />
                      ) : (
                        <>
                          {!utilsHelper.isReadOnly(authContext) ? (
                            <>
                              <Button
                                color="none"
                                className="text-white px-1 border border-white rounded d-flex align-items-center"
                                size="sm"
                                onClick={() =>
                                  setContactModal({
                                    defaultContent:
                                      getCrewEmailDefaultTemplate(crew),
                                    isOpen: true,
                                    isEmail: true,
                                    defaultRecipients: getCrewEmails(crew),
                                    afterSubmit: (data) =>
                                      afterSubmitContactModal(
                                        crew.id,
                                        "email",
                                        data
                                      ),
                                  })
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className="mr-2"
                                />
                                <span>Email</span>
                              </Button>
                              <Button
                                color="none"
                                className="ml-2 text-white px-1 border border-white rounded d-flex align-items-center"
                                size="sm"
                                onClick={() =>
                                  setContactModal({
                                    crewId: crew.id,
                                    isOpen: true,
                                    isEmail: false,
                                    defaultRecipients: getCrewPhones(crew),
                                    afterSubmit: (data) =>
                                      afterSubmitContactModal(
                                        crew.id,
                                        "sms",
                                        data
                                      ),
                                  })
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faSms}
                                  className="mr-2"
                                />
                                <span>SMS</span>
                              </Button>
                            </>
                          ) : null}
                          {workOrderDetails.workOrder.statusId !==
                            STATUS_INVOICED &&
                          !utilsHelper.isReadOnly(authContext) ? (
                            <Button
                              color="none"
                              className="ml-2 text-white px-1 border border-white rounded d-flex align-items-center"
                              size="sm"
                              onClick={() => onEditCrew(shift, outage, crew)}
                            >
                              <FontAwesomeIcon icon={faPen} className="mr-2" />
                              <span>Edit</span>
                            </Button>
                          ) : null}
                          {workOrderDetails.workOrder.statusId !==
                            STATUS_INVOICED &&
                            !utilsHelper.isReadOnly(authContext) && (
                              <Button
                                color="none"
                                className="ml-2 text-white px-1 border border-white rounded d-flex align-items-center"
                                size="sm"
                                onClick={() => onDeleteCrew(crew.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="mr-2"
                                />
                                <span>Delete</span>
                              </Button>
                            )}
                          <Button
                            hidden={utilsHelper.isBetaProd()}
                            color="none"
                            className="ml-2 text-white px-1 border border-white rounded d-flex align-items-center"
                            size="sm"
                            onClick={() => onCrewHistory(crew)}
                          >
                            <FontAwesomeIcon
                              icon={faUserClock}
                              className="mr-2"
                            />
                            <span>History</span>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="pt-4">
                    <div className="d-flex justify-content-between col-12">
                      <div className="flex-shrink-0 flex-grow-0">
                        <h4 className="d-flex flex-column">
                          <span>Crew Members</span>
                          {!crewLead ? (
                            <span className="small">
                              <Badge
                                color={"danger"}
                                className="mt-1"
                                size="14"
                              >
                                Crew Without Lead
                              </Badge>
                            </span>
                          ) : null}
                          <span className="small">
                            <Badge color={"quarter"} className="mt-1" size="14">
                              {
                                crew.employeeCrews.filter(
                                  (ec) => !ec.disabledAt
                                ).length
                              }{" "}
                              of {getShiftCrewSize(shift)}
                            </Badge>
                          </span>
                        </h4>
                      </div>
                      <div className="d-flex flex-grow-1 flex-wrap pl-5">
                        {Object.keys(employeesByRoles)
                          .sort((x, y) => (x < y ? 1 : -1))
                          .map((roleName) => (
                            <div className="mb-4 col-6 col-md-4" key={roleName}>
                              <ListGroup>
                                <ListGroupItem
                                  className="text-underline py-2 border-bottom font-weight-bold text-body bg-lighter text-left"
                                  tag="div"
                                >
                                  {roleName}
                                </ListGroupItem>
                                {employeesByRoles[roleName]
                                  .sort((x, y) =>
                                    x.firstName < y.firstName ? 1 : -1
                                  )
                                  .map((employee) => (
                                    <ListGroupItem
                                      key={employee.id}
                                      className="small py-2 d-flex justify-content-between align-items-center pr-2"
                                      tag="div"
                                    >
                                      <div>
                                        {employee.firstName} {employee.lastName}
                                      </div>
                                      <WorkOrderCrewIndicators
                                        employee={employee}
                                      />
                                    </ListGroupItem>
                                  ))}
                              </ListGroup>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="d-flex flex-column mt-n4">
                      <h4 className="pb-2 mt-4 mb-0 col-12">Schedule</h4>
                      <Table className="mb-0 border">
                        <thead>
                          <tr>
                            <td className="d-flex justify-content-between align-items-center bg-lighter">
                              <span>
                                {workOrderDetails.workOrder.customerName}
                              </span>
                              <div>
                                {!utilsHelper.isReadOnly(authContext) ? (
                                  <Button
                                    className="rounded mr-2"
                                    size="sm"
                                    color="tertiary"
                                    onClick={() =>
                                      setVanDriverModal({
                                        isOpen: true,
                                        crewId: crew.id,
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faCar}
                                      className="mr-2"
                                    />
                                    Set Van Driver
                                  </Button>
                                ) : null}
                                <Badge color={"quarter"}>
                                  {employeeWorkDays.length} of{" "}
                                  {getShiftCrewSize(shift)}
                                </Badge>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <EmployeesWorkDays
                            crew={crew}
                            employeeWorkDays={employeeWorkDays}
                          />
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              );
            })
        )}
      </Col>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : contactModal.isOpen ? (
        <ContactModal
          {...contactModal}
          onClose={() =>
            setContactModal({
              isOpen: false,
              employees: null,
              supervisor: null,
            })
          }
        />
      ) : addWoCrewModal ? (
        <AddWoCrewModal onClose={() => setAddWoCrewModal(false)} />
      ) : vanDriverModal.isOpen ? (
        <AddDriverModal
          defaultCrew={vanDriverModal.crewId}
          type={TYPE_VAN_DRIVER}
          onSubmit={() => {
            setVanDriverModal({});
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setVanDriverModal({})}
        />
      ) : null}
    </Row>
  );
};
export default WorkOrderCrewDetails;
